
import { defineComponent,reactive,toRefs,watch} from "vue";
interface deviceDataF{
    configFlagF:boolean,
    roleData:Array<any>,
}
export default defineComponent({
    name:'RoleConfig',
    components:{
    },
    props:{
        configFlag:{
            type:Boolean,
        }
    },
    emits:['setConfigFlag'],
    setup(props,{emit}){
        const deviceData:deviceDataF = reactive({
            configFlagF:false,
            roleData:[
                {
                    num:100,
                    module:'服务器管理'
                },
                {
                    num:101,
                    module:'IMEI管理'
                },
                {
                    num:102,
                    module:'用户管理'
                },
                {
                    num:103,
                    module:'产品规格管理'
                },
                {
                    num:104,
                    module:'管理员管理'
                }
            ],
        });
        const data = toRefs(deviceData);
        watch(()=>props.configFlag,(n,o)=>{
            deviceData.configFlagF = n;
            console.log(n)
        });
        const closedFun = ()=>{
            console.log('关闭弹窗')
            emit('setConfigFlag',false);
        }
        const configFlagFun =(e:number)=>{
            if(e == 0){
                emit('setConfigFlag',false);
            }else{
                emit('setConfigFlag',false);
            }
           
        }
        return {
          ...data,
          closedFun,
          configFlagFun
        }
    }
})
