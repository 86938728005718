
import {defineComponent,reactive,toRefs } from "vue";
import RoleConfig from './dialong/RoleConfig.vue';
interface deviceDataF{
    statusVal:string,
    statusOption:Array<any>,
    customerName:string,
    customerData:Array<any>,
    total:number,
    pageSize:number,
    pageSizes:Array<any>,
    currentPage:number,
    configFlag:boolean,
}
export default defineComponent({
    name:'RoleTable',
    components:{
        RoleConfig
    },
    setup(){
        const deviceData:deviceDataF = reactive({
            statusVal:'',
            statusOption:[
              {
                  value: '',
                  label: '全部',
              },
              {
                  value: '0',
                  label: '正常',
              },
              {
                  value: '1',
                  label: '冻结',
              }
            ],
            customerName:'',
            customerData:[
              {
                  name:'superadmin',  //账号
                  user_name:'超级管理员',  //姓名
                  phone:'18099998888',  //手机号
                  comment:'超管',  //备注列
                  type:'1',  //账号类型
                  status:'0',  //状态
              },
              {
                  name:'superadmin',  //账号
                  user_name:'超级管理员',  //姓名
                  phone:'18099998888',  //手机号
                  comment:'超管',  //备注列
                  type:'1',  //账号类型
                  status:'0',  //状态
              }
            ],  //
            total:2,
            pageSize:10,
            pageSizes:[10],
            currentPage:1,
            configFlag:false,
        });
        const data = toRefs(deviceData);
        const selectCustomer = ()=>{
            console.log('查询客户');
        }
        const addCustomer = ()=>{
            console.log('添加客户');
        }
        const configFun = (e:any)=>{
            deviceData.configFlag = true;
            console.log('权限配置');
        };
        const editerCustomer = (e:any)=>{
            console.log('编辑');
        };
        const stopCustomer = (e:any)=>{
            console.log('停用');
        }
        const deleteCustomer = (e:any)=>{
            console.log('删除');
        };
        const handleSizeChange = (val:any) => {
          //设置当前页显示几条数据
            deviceData.pageSize = val;
        }
        const handleCurrentChange = (val:any) => {
            //当前页码
            deviceData.currentPage = val;
        }
        const setConfigFlag = (flag:boolean)=>{
            deviceData.configFlag = flag;
        }
        return {
            ...data,
            selectCustomer,
            addCustomer,
            configFun,
            editerCustomer,
            stopCustomer,
            deleteCustomer,
            handleSizeChange,
            handleCurrentChange,
            setConfigFlag
        }
    }
})
